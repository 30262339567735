$avatarBorder: 3px;

.user-profile-header {
  background-color: white;
  padding: 25px;
  position: relative;
  margin-top: 6em;
  border-radius: 4px;
  .avatar-floating {
    position: absolute;
    top: -103px;
  }
  &-content {
    h3 {
      margin: 0;
      color: #333;
    }
    .p-bio {
      color: #585858;
      font-weight: 600;
    }
    .p-location {
      font-size: 14px;
      color: #878787;
    }
  }

  &-links {
    position: absolute;
    top: 25px;
    right: 25px;
    display: flex;
    flex-direction: row;
    gap: 1em;
    svg {
      height: 2em;
    }
  }
}

.user-avatar {
  border-radius: 150px;
  box-shadow: 0 4px 20px -3px #00000025;
  background-color: white;
  overflow: hidden;
  padding: $avatarBorder;
  &-SM {
    width: 44px;
    height: 44px;
    img {
      width: 44px;
      height: 44px;
    }
    .avatar-initials {
      font-size: 0.9em !important;
    }
  }
  &-MD {
    width: 122px;
    height: 122px;
    img {
      width: calc(122px - ($avatarBorder * 2));
      height: calc(122px - ($avatarBorder * 2));
    }
  }

  img {
    border-radius: 150px;
    object-fit: cover;
  }

  .avatar-initials {
    background-color: var(--falcon-primary);
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 3em;
    color: white;
    font-weight: 600;
    height: 100%;
    border-radius: 150px;
  }
}

.avatar-title {
  font-size: 18px;
  font-weight: 600;
  color: #333;
  margin: 0;
  line-height: 20px;
  margin-bottom: 6px;
}

.avatar-sub-title {
  font-size: 14px;
  line-height: 14px;
  font-weight: 600;
  margin: 0;
  // color: $text-600;
}

.user-small-block {
  display: grid;
  grid-template-columns: 60px 1fr;
}
