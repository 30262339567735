@import './custom_mixins';
html {
  width: 100vw;
  overflow-x: hidden;
}
body {
  background: #f2f2f2;
}
[class*='-MenuPortal'] {
  // Your styles specific to elements with a class containing "-menuportal"
  z-index: 99999999999 !important;
}
.katex-html {
  text-align: left;
}
//Hide webpack dev server overlay errors
#webpack-dev-server-client-overlay {
  display: none !important;
}
#mapChart {
  width: 100%;
  .highcharts-legend-item {
    pointer-events: none;
  }
}

.fs-md-0 {
  @media screen and (min-width: 768px) {
    font-size: 1rem !important;
  }
}
.covalo-color {
  color: var(--falcon-primary);
}
//covalo
.covalo-secondary-btn {
  background-color: #fff;
  color: var(--falcon-primary);
  border: 1px solid var(--falcon-primary);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 9px 20px;
}
.covalo-secondary-btn:hover,
.covalo-secondary-btn-active {
  background-color: var(--falcon-primary);
  color: #fff;
}
.covalo-main-btn {
  background-color: var(--falcon-primary);
  color: #fff;
  border: 1px solid var(--falcon-primary);
  font-size: 16px;
  text-transform: uppercase;
  font-weight: 400;
  padding: 9px 20px;
}
.covalo-main-btn:hover,
.covalo-main-btn-active {
  background-color: var(--falcon-primary-hover);
  color: #fff;
  svg {
    color: white;
  }
}

.active--tab {
  border-bottom: 1px solid var(--falcon-primary);
}

.btn-falcon {
  border-radius: 4;
  &-default:disabled {
    color: #748194;
    box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
      0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(51, 51, 51, 0.07),
      0 1px 2px 0 rgba(51, 51, 51, 0.08);
    border: none;
  }
  &-success {
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
      0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(51, 51, 51, 0.07),
      0 1px 2px 0 rgba(51, 51, 51, 0.08);
    color: #479273;
  }
  &-danger {
    background-color: #fff;
    box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
      0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(51, 51, 51, 0.07),
      0 1px 2px 0 rgba(51, 51, 51, 0.08);
    color: #e63757;
    &:disabled {
      color: #748194;
      box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
        0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(51, 51, 51, 0.07),
        0 1px 2px 0 rgba(51, 51, 51, 0.08);
      border: none;
    }
  }
}

.navbar-glass,
.navbar-vertical .navbar-collapse {
  background-color: #ffffff;
  margin-bottom: 2em;
  padding: 25px 20px 16px 20px;
  box-shadow: rgba(255, 255, 255, 0.15) 0px 1px 0px inset,
    rgba(0, 0, 0, 0.075) 0px 1px 5px;
}
h1,
h2,
h3,
h4,
h5 {
  color: #1a535c;
}
h5 {
  font-weight: 400;
}

.downloads-ready-indication {
  position: absolute;
  background-color: $white;
  padding: 0.7em;
  font-size: 0.8333333333rem;
  border-radius: 0.375rem;
  box-shadow: var(--falcon-dropdown-box-shadow);
  width: 9rem;
  right: -45px;
  text-align: center;
  align-items: center;
}

.downloads-ready-indication:after {
  content: '';
  position: absolute;
  z-index: -1;
  width: 1rem;
  height: 1rem;
  background: #000;
  top: -0.375rem;
  left: 4rem;
  transform: rotate(45deg);
  background: inherit;
  border-radius: 0.125rem;
  border-color: var(--falcon-dropdown-border-color);
  border-style: solid;
  border-width: 1px 0 0 1px;
  box-shadow: var(--falcon-box-shadow-sm);
}

.indicator-slide-in {
  position: absolute;
  top: 1em;
  right: 0;
}

.round-dark-border {
  display: inline-block;
  border-radius: 100%;
  border: 1px solid #191919;
  padding: 0.2em;
  transform: scale(0.6);
  svg {
    width: 24px;
    height: 16px;
  }
}

.note-card {
  display: inline-block;
  box-shadow: rgba(0, 0, 0, 0.075) 0px 1px 5px;
  background-color: rgb(249, 249, 215);
  font-size: 0.8em;
  padding: 0.7em 1.2em;
}
.btn-falcon-default-dropdown {
  box-shadow: var(--falcon-btn-falcon-box-shadow);
}
.multi-products-actions-wrapper {
  //   background-color:#ecf2f2;
  padding: 0.7em;
  margin-bottom: 1em;
  gap: 0.7em;
  display: flex;
  flex-wrap: wrap;
  background-color: #faf5f5;

  &-horizontal-colored {
    background-color: transparent;
    button {
      border: none;
      color: white;
      background-color: var(--falcon-primary);
      padding: 7px 26px 7px 8px !important;
      //border-radius: 50px !important;
      display: flex;
      align-items: center;
      &:disabled {
        opacity: 0.5;
      }

      svg {
        height: 15px;
        width: 15px;
        padding: 6px;
        // margin-right: 1em !important;
        // background-color: #fff !important;
        color: #fff;
        border-radius: 50px;
      }
    }
  }
}

.user-initials {
  padding: 0.3em;
  background-color: #ecd5d5;
  border-radius: 3em;
}

.messages-box {
  // height: calc(100% - 19rem);
  position: fixed;
}
@media screen and (max-width: 1180px) {
  .messages-box {
    margin-top: 4em;
    position: relative !important;
    width: auto !important;
  }
}
.noHover:hover {
  color: #5e6e82 !important;
  cursor: inherit !important;
}
.covalo-weight-400 {
  font-weight: 400;
}
.covalo-text-small {
  font-size: 14px;
  line-height: 21px;
}
.covalo-text-medium {
  font-size: 16px !important;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

/* Hide scrollbar for Chrome, Safari and Opera */
.scrollbar-hidden::-webkit-scrollbar {
  display: none;
}
/* Hide scrollbar for IE, Edge and Firefox */
.scrollbar-hidden {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.viewedRow {
  background-color: #f1f1f1 !important;
  * {
    font-weight: 400;
    opacity: 0.95;
  }
}

.text-covalo {
  color: var(--falcon-primary);
}

.logged-out-navbar {
  .active-route:after {
    @media (width>=1365px) {
      bottom: -1.2em;
    }
  }
}

.active-route,
.active-route-dropdown {
  position: relative;
}
.active-route:after {
  @media (width>1179px) {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    border: none;
    border-bottom: 3px solid #343434;
  }
  @media (1179px<width<1364px) {
    bottom: -1.2em;
  }
  @media (width>=1364px) {
    bottom: -1.6em;
  }
}

.active-route-dropdown:after {
  bottom: -1.6em !important;
}
.min-height-row {
  height: 85.1px;
}
.remove-white-space {
  white-space: break-spaces !important;
}

.h4-dark-link {
  color: var(--falcon-1000);
  font-weight: 500;

  &:hover {
    color: #ec6161 !important;

    .icon-arrow-link {
      background: url('https://covalo.com/static/media/arrow_link_coral.cf216271.svg')
        50% no-repeat !important;
    }
  }
}
.icon-arrow-link {
  width: 1.2em;
  height: 1.2em;
  margin-left: 0.3em;
  display: inline-block;
  vertical-align: text-bottom;
  background: url('https://covalo.com/static/media/arrow_link.9e99ecfe.svg') 50%
    no-repeat !important;
}

.dark-link {
  color: var(--falcon-1000);
  font-weight: 500;
  font-size: 14px;
  &:hover {
    text-decoration: none !important;
  }
}
.nav-link {
  transition: 0.2s ease all;
  padding-left: 12px !important;
  padding-right: 12px !important;
}
.nav-link-top,
.nav-item-top {
  color: #343434 !important;
  font-weight: 400;
  font-size: 16px;
  line-height: 24px;
  // padding-bottom: 0 !important;
  font-family: 'Roboto', sans-serif !important;
}
.nav-link-top:hover {
  color: #e66060 !important;
}

.front-office-style-table-element {
  font-size: 14px;
  color: $gray-1100;
  line-height: 16px;
}

.bg-message-covalo {
  background-color: #fee9e9;
}

.bg-message-user {
  background-color: #d2e0fc;
}

.addButton {
  background-color: #205d66;
  border: none;
  font-size: 1.2em;
  transition: 0.2s ease all;
  color: white;
}

.addButton:hover {
  background-color: #1a535c;
}
.dropdown-toggle:after {
  vertical-align: 0.1em !important;
  display: none;
}
.dropdown-title:after {
  display: inline-block;
  margin-left: 0.255em;
  vertical-align: 0.255em;
  content: '';
  border-top: 0.3em solid;
  border-right: 0.3em solid transparent;
  border-bottom: 0;
  border-left: 0.3em solid transparent;
}
.input-elements-indicator {
  margin-left: 0.5rem;
  background: var(--falcon-primary);
  color: white;
  border-radius: 4px;
  font-size: 11px;
  padding: 3px 6px;
  // order: 99
}

.table-covalo:hover {
  background-color: PapayaWhip;
  cursor: pointer;
  td {
    background-color: transparent;
  }
}

.table-hover > tbody > tr:hover > * {
  background-color: transparent !important;
}

.icon-wrapper:hover {
  cursor: pointer;

  svg {
    color: var(--falcon-primary);
  }
}

.disabledButton {
  opacity: 0.6;
  pointer-events: none;
}
label {
  font-weight: 400 !important;
}
.covaloFloatLabel > label {
  top: -2px;
  left: 5px;
  font-weight: 400 !important;
}
.fullWidthModal {
  // .modal-dialog-centered{
  //     align-items:baseline;
  // }

  @media screen and (min-width: 576px) {
    .modal-dialog {
      max-width: 95vw;
    }
  }
  .modal-dialog {
    max-width: 95vw;
    .modal-body {
      min-height: 400px;
      max-height: 75vh;
      overflow-y: scroll;
    }
  }
}
.modal-header {
  padding-inline: 16px 32px;

  h5 {
    margin-bottom: 0;
  }
}
.mediumLargeModal {
  @media screen and (min-width: 576px) {
    .modal-dialog {
      max-width: 75vw;
    }
  }
  .modal-dialog {
    max-width: 75vw;
    .modal-body {
      min-height: 400px;
      max-height: 75vh;
      overflow-y: scroll;
    }
  }
}

.bigModal {
  // .modal-dialog-centered{
  //     align-items:baseline;
  // }
  @media screen and (min-width: 576px) {
    .modal-dialog {
      max-width: 95vw;
    }
  }
  .modal-dialog {
    max-width: 1000px;
    .modal-body {
      min-height: 400px;
      max-height: 75vh;
      overflow-y: scroll;
    }
  }
}

.products-tab-content {
  display: grid;
  grid-template-columns: 300px 1fr;
  @media (width<$mobileWidth) {
    grid-template-columns: 100%;
  }
}
.productPreviewModal {
  // .modal-dialog-centered{
  //     align-items:baseline;
  // }
  @media screen and (min-width: 576px) {
    .modal-dialog {
      max-width: 350px;
    }
  }
  .modal-dialog {
    max-width: 400px;
    .modal-body {
      min-height: 400px;
      max-height: 75vh;
      overflow-y: scroll;
    }
  }
}

// ----------------- formatting select to have label inside ---------

.css-b62m3t-container.filters-select > div {
  border-color: rgb(230, 230, 230) !important;
}
.form-floating > label {
  opacity: 0.65;
  padding-left: 10px !important;
  font-weight: 400 !important;
}
.covalo-float-label > label {
  padding: 0 !important;
  padding-left: 10px !important;
}

.filters-select > div:first-of-type {
  background-color: rgba(255, 255, 255, 0.555);
}
.filters-select > div:first-of-type > div:first-of-type {
  padding-top: 18px;
}

// ----------------- formatting select to have label inside ---------

.Select-placeholder,
.Select--single > .Select-control .Select-value {
  max-width: 100%;
}

.text-covalo {
  color: var(--falcon-primary);
}

// ---------------- nav tab buttons -------------------

.nav-pills,
.nav-tabs {
  .nav-link {
    color: var(--falcon-1000);
    font-weight: 400;
    font-size: $font-size-sm;
    border-bottom: 2px solid transparent;
  }
  .nav-link:hover {
    padding-bottom: 9px;
    border-right: 0px solid transparent !important;
    border-left: 0px solid transparent !important;
    border-top: 0px solid transparent !important;

    color: var(--falcon-primary);
    cursor: pointer;
    border-bottom: 2px solid transparent !important;
  }
  .nav-link.active {
    padding-bottom: 9px;
    border-right: 0px solid transparent;
    border-left: 0px solid transparent;
    border-top: 0px solid transparent;
    // border-bottom: 0px solid transparent;
    border-bottom: 2px solid var(--falcon-primary);
    background-color: white;
    color: var(--falcon-primary);
  }
  .nav-link.active:hover {
    border-bottom: 2px solid var(--falcon-primary) !important;
  }
}
.quotes-tabs.nav-tabs {
  background-color: #fff !important;
  .nav-link {
    height: 64px !important;
  }
}

.address-form {
  .form-control {
    margin-bottom: 6px;
  }
}
.quote-details {
  [aria-disabled='true'] {
    color: rgba(0, 0, 0, 0.5) !important;
    &:hover {
      cursor: not-allowed;
    }
  }
  .react-datepicker .react-datepicker__day {
    &[aria-disabled='false'] {
      &:not(.react-datepicker__day--selected) {
        color: black !important;
      }
    }
  }
  .row {
    margin-right: 1.8rem;
  }
  .input-group-text,
  .form-select,
  .form-control {
    font-size: 0.875rem;
  }
  input[type='number']::-webkit-outer-spin-button,
  input[type='number']::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  input[type='number'] {
    -moz-appearance: textfield;
  }
}

// ---------------- end nav tab buttons -------------------

.no-background-card {
  background-color: transparent;
}
.card {
  border-width: 0px !important;
  box-shadow: none !important;
}

.card-header {
  border-bottom: 1px solid #d6d6d6;
}

.form-control-sm,
.form-select-sm,
.form-control,
.form-select {
  border-radius: 0px;
}

.row {
  --falcon-gutter-x: 1rem;
}

.sticky-top-plus-nav {
  top: 69px;
  z-index: 99;
}

.clickable:hover {
  cursor: pointer;
}

.indicator {
  margin-left: 0.5em;
  border-radius: 50%;
  background-color: var(-falcon-primary-super-light);
  color: $gray-600;
  padding: 0.3rem 0.6em;
  font-size: 0.875rem;
}

.bg-white {
  background-color: white;
}
.bg-covalo-gray {
  background-color: #f2f2f2 !important;
}
.bolder {
  font-weight: bolder;
}
button:hover > svg {
  color: inherit;
}

.sm-multiSelect {
  font-size: 0.875rem;
  div {
    .react-select__indicators {
      .react-select__indicator {
        padding: 4px;
      }
    }
  }
}

.modal-content {
  border-radius: 4px !important;
}

.modal-body {
  overscroll-behavior: none;
}

.falcon-card-headers-end-el-icon-button {
  @media screen and (max-width: 475px) {
    margin-top: 5px;
  }
}

.card-header-covalo {
  background-color: #fcf3f3 !important;
}
.table-wrapper {
  overflow-x: scroll;
}
.table-wrapper::-webkit-scrollbar {
  display: none;
}
thead {
  background-color: #ffffff !important;
  border-bottom: 1px solid var(--falcon-primary);
  th {
    font-weight: 400;
  }
}
// tr{display:inline-block;}
td {
  white-space: break-spaces !important;
}
tbody tr:nth-child(odd) {
  background-color: rgb(255, 255, 255);
}

.text-soft-primary {
  color: var(--falcon-primary)-light;
}

.btn-falcon-success:disabled {
  border-color: $white;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1),
    0 2px 5px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(51, 51, 51, 0.07),
    0 1px 2px 0 rgba(51, 51, 51, 0.08);
}
.btn-falcon-primary {
  background-color: $white;
  color: var(--falcon-primary);
  box-shadow: 0 0 0 1px rgba(88, 75, 75, 0.1),
    0 2px 5px 0 rgba(156, 156, 156, 0.08), 0 0.5px 0.5px 0 rgba(0, 0, 0, 0.04),
    0 1px 2px 0 rgba(0, 0, 0, 0.04);
}

.btn-light {
  background-color: white;
}
.btn-light:hover {
  background-color: #5e6e822e;
  border-color: #fafbfd;
}

.form-control[readonly],
.form-control[readonly]:focus,
.form-control[readonly]:active,
.form-control[readonly]:hover,
select[readOnly],
.notEditableMultiple > div,
.notEditableMultiple > div:hover,
.notEditableMultiple > div:focus,
.notEditableMultiple > div:active {
  background: transparent;
  border-color: transparent;
  outline: transparent;
  box-shadow: none;
  cursor: alias;
  pointer-events: none;
}

.notEditableMultiple > div > div:nth-child(2),
.notEditableMultiple > div > div:nth-child(1) > div > div:nth-child(2) {
  opacity: 0;
}

.editableMulti div {
  border-radius: 0px !important;
}

.css-b62m3t-container > div {
  border-radius: 0px;
}

.card {
  box-shadow: 0 3px 3px 0 rgba(97, 97, 97, 0.24),
    0 3px 6px 0 rgba(255, 255, 255, 0.07);
}
.card,
.card-header:first-child,
.badge {
  border-radius: 0 !important;
}

.rounded-pill {
  border-radius: 50rem !important;
}
// .btn-sm, .btn{
// box-shadow:none;
// }

.card-soft-blue {
  background-color: #e1f1f8;
  color: #003045;
}

.badge {
  padding: 0.5em 1.2em !important;
  font-size: 0.95rem;
  display: inline-flex;
  font-weight: 500;
}

.badge-sm {
  padding: 0.4em 1em !important;
  font-size: 0.765rem;
}
.badge-soft {
  &-info {
    color: #33657b;
  }
  &-warning {
    color: #9d5228;
    background-color: #fde6d8;
  }
  &-success {
    color: #2d5d4a;
    background-color: #dae9e3;
  }
  &-dark {
    color: #070f19;
    background-color: #ced1d4;
  }
  &-primary {
    color: #a34444;
    background-color: #ffe1e1;
  }
}

.badge-soft-filter {
  background-color: #e1e8f3;
  border-radius: 2px;
  color: var(--falcon-body-color);
  .badge-soft-filter {
    border: none !important;
  }
}

.badge-soft-secondary {
  background-color: #f0f0f0;
  transition: 0.2s all ease;
  color: #7d899b;
}
.badge-soft-secondary:hover {
  background-color: #ffe2e2;
}
.dark-tabs .nav-link.active {
  background: #333333;
  color: white;
}
.pagination-footer {
  display: flex;
  justify-content: end;
  margin-top: 0.5rem;
  @media (width<$mobileWidth) {
    justify-content: center;
  }
  &-text {
    @media (width<$mobileWidth) {
      font-size: 10px;
    }
  }
  &-page {
    display: flex;
    justify-content: center;
    align-items: center;
    @media (width<523px) {
      min-width: 50%;
    }
  }
}

.page-header-img {
  width: 100%;
  height: 400px;
  margin: -2rem 0 1rem 0;
  // margin: -2rem -2rem 1rem -2rem;
  object-fit: cover;
}

.white-img-gradient {
  position: absolute;
  bottom: -120px;
  left: 0;
  height: 155%;
  width: 100%;
  // background: radial-gradient(circle, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 48%, rgba(255,255,255,1) 100%);
  background: linear-gradient(
      0deg,
      rgba(255, 255, 255, 1) 10%,
      rgba(255, 255, 255, 0.4) 35%,
      rgba(255, 255, 255, 0) 65%,
      rgba(255, 255, 255, 0) 100%
    ),
    linear-gradient(
      17deg,
      rgba(255, 255, 255, 0.5) 29%,
      rgba(255, 255, 255, 0) 50%
    );
}

//  --------------------------   kanban related overrides -------------------------

.kanban-column {
  width: 17rem;
}
.kanban-item {
  margin-top: 0.5rem;
}
.kanban-column,
.kanban-column-header,
.kanban-items-container {
  transition: 0.2s all ease;
  background-color: #f9f9f9;
}
.kanban-column-active {
  .kanban-column-header,
  .kanban-items-container {
    background-color: #ffe2e2;
  }
}
.kanban-items-container div p {
  font-size: 0.875rem;
}
.kanban-item-card {
  box-shadow: none;
}
.kanban-item-card .close-wrapper {
  padding: 0.6rem;
  margin: -0.5rem;
}
.kanban-item-card .close-wrapper:hover > svg {
  color: var(--falcon-primary) !important;
}
.stickyOnScroll {
  margin-top: 1em;
  position: sticky;
  top: 90px;
  box-shadow: 0 2px 5px 1px #e2e2e2 !important;
}

.stickyOnScroll-no-shadow {
  margin-top: 1em;
  position: sticky;
  top: 110px;
  &-top {
    @extend .stickyOnScroll-no-shadow;
    top: 0px;
  }
}

.no-data-card {
  padding: 0.5rem 3em 1em 1em !important;
  border-radius: 10px !important;
  box-shadow: 0 2px 5px 1px #e2e2e2 !important;
  h4 {
    font-weight: 400;
  }
}

.no-data-card-body {
  display: flex;
  justify-content: flex-start;
  min-width: 380px;
  gap: 1em;
}

.no-data-card-icon {
  margin-left: 15px;
  margin-top: 15px;
  flex: 0.1;
}

.no-data-card-icon-button {
  float: right;
  margin-right: 10px;
}

.no-data-card-p-div {
  margin-right: 10px;
  // flex: 1
}

.no-data-card-description {
  font-size: 16px;
}
.no-data-card-contacts {
  h4 {
    font-weight: 400;
  }
}

.details-panel {
  position: relative;
  left: -7px;
  flex: 0.5;
  background-color: white;
  border-left: 1px solid rgb(230, 230, 230);
  @media screen and (max-width: 576px) {
    flex: 1;
    position: unset;
    margin-right: 0.5rem !important;
    margin-left: 0.5rem !important;
  }
}

.details-panel-items {
  justify-content: flex-start;
  align-items: baseline;
  @media screen and (max-width: 900px) {
    display: block !important;
  }
}

.close-details-panel {
  position: absolute;
  top: -5px;
  right: 5px;
  @media screen and (max-width: 576px) {
    top: 0;
  }
}

//  --------------------------   kanban related overrides end -------------------------

// -------------- Images -----------
.asset-thumbnail {
  //width: 6em;
  //height: 6em;
  height: auto;
  width: auto;
  max-height: 10em;
  max-width: 15em;
}

.company-asset {
  &-header {
    aspect-ratio: 5/1;
    display: flex;
    align-items: center;
    width: 100%;
    border: 1px solid var(--falcon-input-border-color);
    &-img {
      aspect-ratio: 5/1;
      width: 100%;
    }
  }
  &-logo {
    border: 1px solid var(--falcon-input-border-color);
    height: 160px;

    &-logo {
      margin: 0 auto;
      width: fit-content;
      height: fit-content;

      &-img {
        max-width: 300px;
        object-fit: contain;
        height: auto;
      }
    }
    &-segment {
      width: 300px;
      height: 300px;
      &-img {
        aspect-ratio: 1/1;
        width: 300px;
        height: 300px;
        object-fit: contain;
      }
    }
  }
}

// -------------- Images end-----------

// -------------- Project -----------

.project-card-content {
  height: 100%;
  background-color: rgba(255, 255, 255, 0.7);

  transition: 0.2s ease all;
  a,
  a:hover {
    display: flex;
    flex-direction: column;
    height: 100%;
    color: inherit;
    text-decoration: none;
  }
}
.image-uploader {
  width: 100%;
  cursor: pointer;
}
.project-card-content:hover {
  background-color: rgba(255, 255, 255, 1);
}
.preview-image-max-height {
  max-height: 10em;
}
.project-image-preview {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 4/3;
}
.project-card-image {
  width: 100%;
  height: auto;
  object-fit: cover;
  aspect-ratio: 13/4;
}

.project-card-profile {
  border-radius: 50rem;
  border: 3px solid var(--falcon-primary);
  outline: 2px solid rgb(255, 255, 255);
  object-fit: cover;
}
.project-card-profile-admin {
  width: 28px;
  height: 28px;
}
.project-card-profile-member {
  width: 28px;
  height: 28px;
  left: -0.6em;
  position: relative;
}

.project-profile {
  border-radius: 50rem;
  border: 3px solid var(--falcon-primary);
  outline: 2px solid rgb(255, 255, 255);
  object-fit: cover;
  width: 36px;
  height: 36px;
  margin: 0 8px 8px 0;
}

.comment-input {
  position: relative;
  .comment-input-icon {
    position: absolute;
    padding: 0.35rem 0.6rem;
    top: 0;
    right: 0;
    background-color: transparent;
    border: none;
    border-left: 1px solid rgb(211, 211, 211);
  }
  .comment-input-icon:hover {
    svg {
      color: var(--falcon-primary);
    }
  }
}

.table-filters {
  font-size: smaller !important;
  margin: 1em 0;
}

// -------------- Project end-----------

.quick-sort-btn {
  padding: 0.3em 1em;
  border: none;
  margin-right: 1em;
  background-color: transparent;
  transition: 0.2s ease all;
  color: #2a2a2a;
  span {
    background-color: var(-falcon-primary-light);
    padding: 0.2em 0.4em;
    margin: 0 0 0 0.5em;
    border-radius: 2px;
  }
}

.quick-sort-btn-active,
.quick-sort-btn:hover {
  background-color: var(--falcon-primary);
  color: white;
  span {
    background-color: transparent;
  }
}

#verified-company-switch.form-check-input:checked,
#enable-company-switch.form-check-input:checked {
  background-color: forestgreen !important;
  border-color: forestgreen !important;
}

.disable-click {
  pointer-events: none;
}

.required .form-label:after {
  content: ' *';
  color: var(--falcon-primary);
}
.required-checkbox .form-check-label:before {
  content: '* ';
  color: var(--falcon-primary);
}

.w-fit-content {
  width: fit-content;
}

.w-70 {
  width: 70%;
}
.w-30 {
  width: 30%;
}

.quote-stepper {
  margin-left: -16%;
  margin-right: -16%;
  .nav-link {
    padding-left: 0 !important;
    padding-right: 0 !important;
    pointer-events: none !important;
    color: #aeb8c5;
    svg {
      color: #aeb8c5;
    }
    &.active {
      background-color: transparent;
      color: #479273;
      svg {
        color: #479273;
      }
      &.notSuccess {
        color: var(--falcon-primary);
        .nav-item-circle {
          background-color: #fff !important;
          border-color: var(--falcon-primary);
          svg {
            color: var(--falcon-primary);
          }
        }
      }
      .nav-item-circle {
        background-color: #fff !important;
        border-color: #479273;
      }
    }
    &.done {
      .nav-item-circle {
        background-color: #fff !important;
        svg {
          color: #479273;
        }
      }
    }
    .nav-item-circle {
      height: 1.75rem;
      width: 1.75rem;
      line-height: 1.5rem;
    }
  }
}

.covalo-icon-round {
  display: flex;
  text-align: center;
  width: 2rem;
  height: 2rem;
  font-size: 0.75rem;
  border-radius: 50%;
}
.covalo-icon-square {
  color: var(--falcon-primary);
  border: 1px solid var(--falcon-primary);
  padding: 6px;
  width: 14px;
  height: 14px;
}

.back-button {
  background-color: #e1e1e1;
  font-size: 10px;
  font-weight: 400;
  margin-right: 3px;
  color: #838383;
  &:hover {
    cursor: pointer;
  }

  .back-icon {
    color: #9fa0a2;
  }
}

.placeholder-covalo {
  color: #b6c1d2 !important;
  option {
    color: #344050;
  }
}

.right {
  float: right;
}

.spinner-row {
  padding: 6px;
  justify-content: center;
  transform: scale(0.5);
  .spinner-grow {
    animation-duration: 1.75s;
    margin: 0px 6px;
    width: 1rem;
    height: 1rem;
  }
}

.css-14el2xx-placeholder {
  color: #b6c1d2 !important;
  border-color: #479273;
}

.overflow-ellipsis {
  text-overflow: ellipsis;
  white-space: nowrap;
}

@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

.fade-in {
  animation: fadeIn 0.5s;
}

.centered {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 200;
}

.grey-information-box {
  background-color: #f6f6f6;
  border: 1px solid #e9f1f3;
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 17px !important;
  padding-top: 20px !important;
}

// react-select

.css-1s2u09g-control {
  min-height: 36px !important;
  // border-color: hsl(0, 0%, 85%) !important;
  color: var(--falcon-input-color);
  border: 1px solid var(--falcon-input-border-color) !important;
  background-clip: padding-box;
  box-shadow: var(--falcon-box-shadow-inset);
  div {
    border: none;
  }
}
.css-1pahdxg-control {
  min-height: 36px !important;
}
// .css-b62m3t-container {
//   transform: scaleY(calc(36 / 38)) translateY(-1px);
// }

//
.fixed-height-modal {
  overflow-y: hidden;
  .modal-dialog-centered {
    min-height: auto;
    height: 100vh;
    margin-top: 0;
  }
}
.modified-modal-footer {
  display: flex;
  flex-shrink: 0;
  align-items: center;
  justify-content: space-between;
  padding: 0.75rem;
  border-top: 1px solid var(--falcon-border-color);
  border-bottom-right-radius: calc(0.375rem - 1px);
  border-bottom-left-radius: calc(0.375rem - 1px);
}

.alert-info {
  background-color: #f4ecc9;
  border-color: #f2efdc;
  color: #766917;
}

.preferencesBlock {
  display: flex;
  gap: 5em;
  justify-content: space-between;
  margin: 1em 0;
  &-topic {
    width: 55%;
    &-title {
      color: $gray-1100;
      margin: 0;
      font-weight: 600;
      font-size: 1.2em;
    }
    &-description {
      margin: 0;
    }
  }

  &-children {
    max-width: 650px;
  }

  @media screen and (max-width: 880px) {
    flex-direction: column;
    gap: 2em;
    &-topic {
      width: 100%;
    }
  }
}

.PhoneInputInput {
  @extend .form-control;
  .white-shadowed-btn {
    background-color: white;
  }
}

.main-white-btn {
  background-color: white !important;
  color: $gray-900 !important;
  border-radius: 5px !important;
  transition: 0.2s ease all;

  &:hover {
    color: $gray-1100;
  }
}

.secondary-white-btn {
  background-color: transparent !important;

  color: white !important;
  border-radius: 5px !important;
  transition: 0.2s ease all;
  &:hover {
    background-color: white !important;
    color: var(--falcon-primary) !important;
  }
}

.btn.secondary-white-btn {
  border: 1px solid white !important;
}

.center-center {
  display: flex;
  align-items: center;
  justify-content: center;
}

@media (min-width: 1300px) {
  .modal-xl {
    max-width: 1280px;
  }
}

@media (max-width: 1300px) {
  .modal-xl {
    max-width: 1200px;
  }
}

@media (max-width: 1199px) {
  .modal-xl {
    max-width: 1000px !important;
  }
}

.modal.fade {
  background: rgba(0, 0, 0, 0.5);
}

.modal-backdrop.fade {
  opacity: 0;
}

.request-title-div {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  @media screen and (max-width: 1180px) {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }
}

.request-title-div {
  @media screen and (min-width: 1180px) {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
  }
}

.fix-margin-right {
  margin-right: -0.5rem !important;
}

.btn-falcon-default {
  color: #404a57;
  box-shadow: 0 0 0 1px rgba(43, 45, 80, 0.1), 0 2px 5px 0 rgba(43, 45, 80, 0.1),
    0 3px 9px 0 rgba(43, 45, 80, 0.08), 0 1px 1.5px 0 rgba(51, 51, 51, 0.08),
    0 1px 2px 0 rgba(51, 51, 51, 0.08);
  background: $white;
  &:hover {
    background: $white;
    svg {
      fill: var(--falcon-primary);
    }
  }
  &:focus {
    background: var(--falcon-primary);
    color: white !important;
    svg {
      fill: var(--falcon-primary);
    }
  }
}
.grey-information-box {
  grid-template-columns: 120px 1fr;
  background-color: #f6f6f6;
  border: 1px solid #e9f1f3;
  margin-bottom: 20px !important;
  padding-bottom: 20px !important;
  padding-left: 10px !important;
  padding-right: 10px !important;
  padding-top: 20px !important;
}
.product-attribute-value {
  line-height: 1.4em;
}
.product-attribute-section {
  display: grid;
  grid-template-columns: 100px 1fr;
  gap: 0.5em;
  color: #333;
  font-size: 15px;
  line-height: 1.4em;
  margin: 1em 0;
  @media screen and (max-width: 768px) {
    display: block !important;
  }
}
.info-block {
  padding: 1.5em;
  margin-bottom: 2em;
}
.info-block p {
  @extend %company-page-font;
}

.product-page-wrapper .product-attribute-value {
  color: #333;
  font-size: 15px;
  line-height: 25.7143px;
  margin: 0;
  font-weight: 400;
}

.product-attribute-title {
  color: #333;
  font-size: 15px;
  line-height: 25.7143px;
  margin: 0;
  font-weight: 700;
}

a:hover {
  text-decoration: none;
}

.card-box-shadow {
  box-shadow: 0 0 5px 0 #c7c7c7;
  &:hover {
    box-shadow: 0 0 8px 0 #c7c7c7;
  }
}

.documents-table {
  overflow-y: auto;
  max-height: 275px;
  &-row {
    border: 1px solid rgb(197, 208, 224);
    border-radius: 5px;
    margin: 5px 0;
  }
}
.status-tag {
  background-color: black;
  font-family: 'Roboto';
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  padding: 4px 8px 3px 8px;
  color: #fff;
  // text-align: center;
  width: fit-content;
  display: inline-block;
  &-QUARANTINED {
    background-color: #de8344;
  }
  &-DISCONTINUED {
    background-color: #4f71be;
  }
  &-AVOID {
    background-color: #6a99d0;
  }
  &-APPROVED {
    background-color: #7eaa55;
  }
  &-DISCONTINUED {
    background-color: orange;
  }
  &-BLACKLISTED-INCI {
    background-color: #343434;
  }
  &-UNDER-DISCUSSION {
    background-color: #808026;
  }
  &-DESTRUCT {
    background-color: #808080;
  }
  &-UNDER-ASSESSMENT {
    background-color: #4fad5b;
  }
  &-REJECTED {
    background-color: #b02418;
  }
  &-PROHIBITED {
    background-color: #92683c;
  }

  @media screen and (max-width: 576px) {
    font-size: 9px;
    line-height: 10px;
    //padding: 2px 4px;
    font-weight: 400;
    text-transform: uppercase;
  }
  &-sm {
    font-weight: 400;
    font-size: 9px;
    line-height: 10px;
    //padding: 2px 4px;
    text-transform: uppercase;
  }
}

.unset-height {
  height: unset !important;
}

.secondary-anchor {
  color: $secondary-anchor;
}

.product-attribute-title {
  font-weight: 700;
}

.p-20 {
  padding: 20px;
}

.m-20 {
  margin: 20px;
}

.optionStyle {
  color: $gray-700;
}

.react-select__menu {
  z-index: 5 !important;
}

.react-select__option:has(.optionStyle) {
  display: flex;
  align-items: center;
}

.react-select__option--is-selected:has(.optionStyle),
.react-select__option--is-focused:has(.optionStyle) {
  .optionStyle {
    color: white !important;
  }
}

.limit-text-3-lines {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
  text-overflow: ellipsis;
}

.nav-link.active-route-mobile {
  color: var(--falcon-primary) !important;
}

.nav-link.active-route {
  &:after {
    border: none;
  }
  color: var(--falcon-primary) !important;
}

.nav-link-top.active-route {
  &:after {
    content: '';
    position: absolute;
    width: 100%;
    left: 0;
    border: none;
    border-bottom: 3px solid #343434;
  }
  @media (width<1180px) {
    color: var(--falcon-primary) !important;
    &:after {
      border-bottom: none;
    }
  }
}

.clickable-switch {
  .form-check-input:hover {
    cursor: pointer;
  }
}
.covalo-link {
  text-decoration: underline;
  color: var(--falcon-primary) !important;

  &:hover {
    color: var(--falcon-primary);
    text-decoration: underline;
    font-weight: bolder;
  }
}

.table-link {
  font-size: 12px;
  font-weight: 700;
  text-decoration: underline;
}

.btn-default {
  border: none;
}
.navbar-toggler {
  padding: 0 !important;
  border: none !important;
}
.navbar-toggler-humburger-icon {
  padding: 5px !important;
}

.form-control:disabled,
.form-select:disabled,
.PhoneInputInput:disabled {
  background-color: #f4f4f4;
  color: #c2c2c2;
}

%company-page-font {
  font-family: Roboto;
  font-size: 16px !important;
  font-weight: 300;
  letter-spacing: 0em;
  text-align: left;
  color: #343434;
  line-height: 1.42857143;
}
%company-page-header-font {
  font-family: Roboto;
  font-size: 24px !important;
  font-weight: 300 !important;
  letter-spacing: 0em;
  text-align: left;
  color: #1a535c;
  line-height: 1.42857143;
}
%company-page-header-subtitles {
  @extend %company-page-header-font;
  letter-spacing: 1px;

  font-size: 20px !important;
}
.search-overview-sort {
  font-size: 14px !important;
  color: #a6a6a6;
  max-width: unset;
}

.highcharts-scrolling-parent {
  ::-webkit-scrollbar {
    -webkit-appearance: none;
    height: 7px;
  }

  ::-webkit-scrollbar-thumb {
    border-radius: 4px;
    height: 7px;
    background-color: rgb(182 193 210 / 55%);
  }
}

.form-check-input:checked {
  background-color: var(--falcon-primary);
  border-color: var(--falcon-primary);
}

input[type='search'] {
  &::-webkit-search-decoration,
  &::-webkit-search-cancel-button,
  &::-webkit-search-results-button,
  &::-webkit-search-results-decoration {
    display: none;
  }
}

.toast-container-uxbeauty {
  font-family: 'Jost' !important;
}
